import React, { useEffect } from "react";
import FormLayout from "../FormLayout";
import Button from "common/components/atoms/Button";
import styles from "./InfluencerDashboard.module.scss";
import usePreviewPdf from "common/hooks/usePreviewPdf";
import useError from "common/hooks/useErrors";
import Loader from "common/components/atoms/Loader";
import Text from "common/components/atoms/Text";
import useProfile from "common/hooks/useProfile";
import useInfluencersStore from "store/influencersStore";

const InfluencerDashboard = () => {
  const { pdfData, isLoading, error } = usePreviewPdf();
  const { contractorDetails } = useProfile();
  const setError = useError((state) => state.setError);
  const setCurrentInfluencer = useInfluencersStore(
    (state) => state.setCurrentInfluencer
  );

  useEffect(() => {
    if (error) setError(error);
    if (contractorDetails)
      setCurrentInfluencer(contractorDetails.data.influencer);
  }, [error, setError, contractorDetails, setCurrentInfluencer]);

  const openPdfPreview = () => {
    if (pdfData) {
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
      const downloadUrl = URL.createObjectURL(pdfBlob);
      window.open(downloadUrl, "_blank");
      return downloadUrl;
    }
  };

  return (
    <FormLayout>
      <Text fw="bold" sz="xs" ta="center" color="red">
        ※ご契約の連絡で公式LINEの登録は必須となります
      </Text>
      <img
        src="./assets/icons/contract-sign.png"
        alt="qr-code"
        className={styles.qrCode}
      />
      <a
        href="https://lin.ee/QT5TLA3"
        className={styles.lineLink}
        target="_blank"
        rel="noreferrer"
      >
        公式LINEはこちら
      </a>
      <Button
        variant="yellow"
        icon={"./assets/icons/open-tab.svg"}
        iconPosition={!isLoading ? "right" : undefined}
        onClick={openPdfPreview}
        disabled={isLoading || !pdfData}
      >
        {isLoading && <Loader />}契約を確認する
      </Button>
    </FormLayout>
  );
};

export default InfluencerDashboard;
