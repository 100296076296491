import React, { useState } from "react"
import Payout from "../../../common/components/templates/PayoutModals/Payout/Payout"
import { AccountRegistration } from "common/components/templates/PayoutModals/AccountRegistration"
import { BankRegistration } from "common/components/templates/PayoutModals/BankRegistration"

const EditBankDetails = () => {
  const [isEdit, setIsEdit] = useState(true)
  const isEnglish = window.location.href.includes("/en/")

  return (
    <Payout>
      {isEnglish ? (
        <BankRegistration isEdit={isEdit} setIsEdit={setIsEdit} />
      ) : (
        <AccountRegistration isEdit={isEdit} setIsEdit={setIsEdit} />
      )}
    </Payout>
  )
}

export default EditBankDetails
