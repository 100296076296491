import React from "react"
import styles from "./../../templates/PayoutModals/Payout/Payout.module.scss"

interface Option {
  value: string
  label: string
}

interface RadioGroupProps {
  title: string
  options: Option[]
  register: any
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  title,
  options,
  register,
}) => {
  return (
    <div className={styles.radioContainer}>
      <p className={styles.smallText}>{title}</p>
      <div className={styles.radioContent}>
        {options.map((option) => (
          <label key={option.value}>
            <input
              type="radio"
              value={option.value}
              {...register}
              style={{ accentColor: "#722E69" }}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

export default RadioGroup
