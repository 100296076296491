import useSWR from "swr";
import { useAPI } from "./useAPI";

const useRevenue = () => {
  const { get } = useAPI();

  const {
    data: revenueData,
    error,
    mutate,
    isLoading,
  } = useSWR<RevenueData>(
    `/utils/expectedRevenue/get`,
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  return {
    revenueData,
    isLoading,
    error,
    mutateInfluencers: mutate,
  };
};

export default useRevenue;
