import React, { useState, useEffect } from "react"
import Button from "common/components/atoms/Button"
import Input from "common/components/atoms/Input"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import usePayoutBank from "common/hooks/usePayoutBank"
import useEditAccountDetails from "common/hooks/useEditAccountDetails"
import { useNavigate } from "react-router-dom"
import Text from "common/components/atoms/Text"
import { useTranslation } from "react-i18next"
import Loader from "common/components/atoms/Loader"
import styles from "../Payout/Payout.module.scss"

interface AccountRegistrationProps {
  isEdit?: boolean
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
}

interface PayoutBank {
  bankName: string
  bankBranch: string
  bankNo: string
  bankType: string
  bankAccountHolder: string
  iBan?: string
  swiftCode?: string
  bankBranchAddress?: string
  transit?: string
  institutionNo?: string
}

const AccountRegistration = ({
  isEdit,
  setIsEdit,
}: AccountRegistrationProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [bankDetails, setBankDetails] = useState<PayoutBank>()

  const { onAccountUpdate, isLoading } = useEditAccountDetails()
  const { payoutBank } = usePayoutBank()

  const isEnglish = window.location.href.includes("/en/")

  const fetchBankDetails = () => {
    if (payoutBank?.success) {
      setBankDetails(payoutBank.result)
    }
  }

  useEffect(() => {
    fetchBankDetails()
  }, [isEdit])

  const validationSchema = yup.object().shape({
    bankName: yup.string().required(t("payout.RequiredField")),
    bankBranch: yup.string().required(t("payout.RequiredField")),
    bankNo: yup.string().required(t("payout.RequiredField")),
    bankAccountHolder: yup.string().required(t("payout.RequiredField")),
    iBan: yup.string().required(t("payout.RequiredField")),
    swiftCode: yup.string().required(t("payout.RequiredField")),
    bankBranchAddress: yup.string(),
    transit: yup.string(),
    institutionNo: yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (bankDetails) {
      setValue("bankName", bankDetails.bankName)
      setValue("bankBranch", bankDetails.bankBranch)
      setValue("bankBranchAddress", bankDetails.bankBranch)
      setValue("bankNo", bankDetails.bankNo)
      setValue("bankAccountHolder", bankDetails.bankAccountHolder)
      setValue("iBan", bankDetails.iBan || "")
      setValue("swiftCode", bankDetails.swiftCode || "")
      setValue("transit", bankDetails.transit || "")
      setValue("institutionNo", bankDetails.institutionNo || "")
    }
  }, [bankDetails, setValue, isEnglish])

  const handleAccountUpdate = (data: any) => {
    onAccountUpdate(data)
      .then((res) => {
        console.log(res)
        navigate(isEnglish ? "/en/payout/bank/confirm" : "/payout/bank/confirm")
      })
      .catch(() => {
        console.log("error")
      })
  }

  return (
    <div className={styles.accountRegistration}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <Text>{t("payout.AccountRegistrationTitle")}</Text>
        </div>
        <div className={styles.Inputwrapper}>
          <Input
            label={t("payout.FinancialInstitutionName")}
            placeholder={t("payout.SelectFinancialInstitution")}
            id="bankName"
            type="string"
            inputProps={{ ...register("bankName") }}
            error={errors?.bankName?.message}
          />
          <Input
            label={t("payout.BranchName")}
            placeholder={t("payout.EnterBranchName")}
            id="bankBranch"
            inputProps={{ ...register("bankBranch") }}
            type="string"
            error={errors?.bankBranch?.message}
          />
          <Input
            label={t("payout.BranchAddress")}
            placeholder={t("payout.EnterBranchAddress")}
            id="bankBranchAddress"
            inputProps={{ ...register("bankBranchAddress") }}
            type="string"
            error={errors?.bankBranchAddress?.message}
          />
          {/* <div> */}
            {/* <Text className={styles.smallText}>
              {t("payout.accountNumber")}
            </Text> */}
            <Input
              label={t("payout.accountNumber")}
              placeholder={t("payout.EnterAccountNumber")}
              id="bankNo"
              inputProps={{ ...register("bankNo") }}
              type="string"
              error={errors?.bankNo?.message}
            />
          {/* </div> */}
          <Input
            label={t("payout.AccountHolder")}
            placeholder={t("payout.EnterAccountNameKana")}
            id="bankAccountHolder"
            inputProps={{ ...register("bankAccountHolder") }}
            type="string"
            error={errors?.bankAccountHolder?.message}
          />
          <Input
            label={t("payout.IBan")}
            placeholder={t("payout.EnterIBan")}
            id="iBan"
            inputProps={{ ...register("iBan") }}
            type="string"
            error={errors?.iBan?.message}
          />
          <Input
            label={t("payout.SwiftCode")}
            placeholder={t("payout.EnterSwiftCode")}
            id="swiftCode"
            inputProps={{ ...register("swiftCode") }}
            type="string"
            error={errors?.swiftCode?.message}
          />
          <Input
            label={t("payout.Transit")}
            placeholder={t("payout.EnterTransit")}
            id="transit"
            inputProps={{ ...register("transit") }}
            type="string"
            error={errors?.transit?.message}
          />
          <Input
            label={t("payout.InstitutionNo")}
            placeholder={t("payout.EnterInstitutionNo")}
            id="institutionNo"
            inputProps={{ ...register("institutionNo") }}
            type="string"
            error={errors?.institutionNo?.message}
          />
          <div className={styles.btnWrapper}>
            <Button
              onClick={handleSubmit(handleAccountUpdate)}
              disabled={isLoading}
            >
              {isLoading && <Loader />}
              {t("payout.GoToConfirmationScreen")}
            </Button>
            <Button onClick={() => navigate(-1)}>{t("payout.GoBack")}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountRegistration
