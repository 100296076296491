import React from "react"
import Payout from "../../../common/components/templates/PayoutModals/Payout/Payout"
import { AccountRegistrationCompleted } from "common/components/templates/PayoutModals/AccountRegistrationCompleted"

const ConfirmationPage = () => {
  return (
    <Payout>
      <AccountRegistrationCompleted />
    </Payout>
  )
}

export default ConfirmationPage
