import React from "react"
import Payout from "common/components/templates/PayoutModals/Payout/Payout"
import { AccountRegistration } from "common/components/templates/PayoutModals/AccountRegistration/index"
import { BankRegistration } from "common/components/templates/PayoutModals/BankRegistration"

const AddBankDetails = () => {
  const isEnglish = window.location.href.includes("/en/")

  return (
    <Payout>
      {isEnglish ? <BankRegistration /> : <AccountRegistration />}
    </Payout>
  )
}

export default AddBankDetails
