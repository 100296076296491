import JA_BUTTONS from "./buttons.json";
// import JA_CHANGE_STATUS from "./changeStatus.json";
import JA_COMMON from "./common.json";
import JA_ERRORS from "./errors.json";
import JA_FIELDS from "./fields.json";
import JA_LABELS from "./labels.json";
import JA_LINKS from "./links.json";
import JA_NAVIGATIONS from "./navOptions.json";
// import JA_ORDER_STATUS from "./orderStatus.json";
// import JA_PAYMENT_TYPES from "./paymentTypes.json";
import JA_PLACEHOLDERS from "./placeholders.json";
import JA_TABLE_TITLES from "./tableTitles.json";
import JA_TITLES from "./titles.json";
import JA_PAYOUT from "./payout.json";

export const translations = {
  buttons: JA_BUTTONS,
  // changeStatus: JA_CHANGE_STATUS,
  common: JA_COMMON,
  errors: JA_ERRORS,
  fields: JA_FIELDS,
  labels: JA_LABELS,
  links: JA_LINKS,
  navOptions: JA_NAVIGATIONS,
  // orderStatus: JA_ORDER_STATUS,
  // paymentTypes: JA_PAYMENT_TYPES,
  placeholders: JA_PLACEHOLDERS,
  tableTitles: JA_TABLE_TITLES,
  titles: JA_TITLES,
  payout: JA_PAYOUT,
};
