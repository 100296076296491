import React, { Fragment, useRef, ChangeEvent, useState } from "react"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { emailRegex, passwordRegex } from "utils/constant"
import { getAddressbyPostalCode, getErrorMessage } from "utils/helper"
import styles from "./InfluencerContract.module.scss"
import useAuthStore from "store/authStore"

interface InfluencerProps {
  onProceed: () => void
  onPrevious: () => void
}

const InfluencerContract = ({ onProceed, onPrevious }: InfluencerProps) => {
  const validationSchema = yup.object().shape({
    familyName: yup.string().required("必須項目です"),
    firstName: yup.string().required(getErrorMessage("1010")),
    familyNameKana: yup.string().required("必須項目です"),
    firstNameKana: yup.string().required("必須項目です"),
    zipcode: yup
      .string()
      .required("郵便番号は必須です")
      .test("valid-length", getErrorMessage("1014"), (value) =>
        value ? value.length === 7 : true
      ),
    province: yup.string().required("必須項目です"),
    buildingName: yup.string(),
    address: yup.string().required("必須項目です"),
    city: yup.string().required("必須項目です"),
    email: yup
      .string()
      .email(getErrorMessage("1006"))
      .required(getErrorMessage("1005"))
      .matches(emailRegex, getErrorMessage("1006")),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")], getErrorMessage("1007"))
      .required(getErrorMessage("1006")),
    password: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
    mainChannelName: yup.string().required("必須項目です"),
    youtubeChannelUrl: yup
      .array()
      .of(
        yup
          .object({ url: yup.string().required("必須項目です") })
          .required("YouTubeチャンネルのURLを最低1ついれてください。")
      )
      .required("YouTubeチャンネルのURLを最低1ついれてください。"),
    youtubeShortCount: yup
      .number()
      .typeError("数字を入力してください")
      .required("必須項目です"),
    // .positive("0より大きい数字を入力してください")
    agency: yup.boolean().required("必須項目です"),
    isCompany: yup.boolean().required("必須項目です"),
    companyName: yup.string().when("isCompany", {
      is: true,
      then: (schema) => schema.required("必須項目です"),
    }),
    averageViewCounts: yup
      .number()
      .typeError("数字を入力してください")
      .required("必須項目です"),
    // .positive("0より大きい数字を入力してください")
    screenShot: yup.string().required("必須項目です"),
    inviteName: yup.string(),
  })

  const userRegister = useAuthStore((state) => state.userRegister)
  const setUserRegister = useAuthStore((state) => state.setUserRegister)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const setSelectedFile = useAuthStore((state) => state.setSelectedFile)
  const selectedFile = useAuthStore((state) => state.selectedFile)

  const [isCompanyVisible, setCompnayVisible] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      familyName: userRegister.familyName,
      firstName: userRegister.firstName,
      familyNameKana: userRegister.familyNameKana,
      firstNameKana: userRegister.firstNameKana,
      province: userRegister.province,
      buildingName: userRegister.buildingName,
      address: userRegister.address,
      zipcode: userRegister.zipcode,
      city: userRegister.city,
      email: userRegister.email,
      confirmEmail: userRegister.email,
      password: userRegister.password,
      mainChannelName: userRegister.mainChannelName,
      youtubeChannelUrl: userRegister.youtubeChannelUrl.length
        ? userRegister.youtubeChannelUrl.map((url) => ({ url }))
        : [{ url: undefined }],
      youtubeShortCount: userRegister.youtubeShortCount,
      agency: userRegister.agency,
      isCompany: userRegister.isCompany,
      companyName: userRegister.isCompany ? userRegister.companyName : "",
      averageViewCounts: userRegister.averageViewCounts,
      screenShot: selectedFile?.name || "",
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "youtubeChannelUrl",
  })

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      setValue("screenShot", selectedFile.name)
      setSelectedFile(selectedFile)
    }
  }

  const handleRegister = (values: any) => {
    const prepareData = {
      ...values,
      youtubeChannelUrl: values.youtubeChannelUrl.map(
        (item: { url: any }) => item.url
      ),
      companyName: values.isCompany ? values.companyName : "",
      inviteName: values.inviteName?.length ? values.inviteName : null,
    }
    setUserRegister(prepareData)
    onProceed()
  }

  const getAddress = (address: AddressResponseData) => {
    if (address.area) setValue("address", address.area)
    if (address.city) setValue("city", address.city)
    if (address.province) setValue("province", address.province)
    if (address.street) setValue("address", address.street)
  }

  return (
    <>
      <Title order={5} color="purple" fw="bold">
        契約確認
      </Title>
      <div className={styles.agencyCheckBox}>
        <Text fw="bold">法人ですか？</Text>
        <input
          type="checkbox"
          {...register("isCompany")}
          onClick={() => setCompnayVisible(!isCompanyVisible)}
        />
      </div>
      {isCompanyVisible && (
        <Input
          label={"法人名"}
          placeholder="法人名を入力してください"
          id={"companyName"}
          inputProps={{ ...register("companyName") }}
          error={errors.companyName?.message}
        />
      )}
      <Input
        label={"苗字"}
        placeholder="苗字を入力してください"
        id={"familyName"}
        inputProps={{ ...register("familyName") }}
        error={errors.familyName?.message}
      />
      <Input
        label={"名前"}
        placeholder="名前を入力してください"
        id={"firstName"}
        inputProps={{ ...register("firstName") }}
        error={errors.firstName?.message}
      />
      <Input
        label={"苗字（カタカナ）"}
        placeholder="苗字（カタカナ）を入力してください"
        id={"familyNameKana"}
        inputProps={{ ...register("familyNameKana") }}
        error={errors.familyNameKana?.message}
      />
      <Input
        label={"名前（カタカナ）"}
        placeholder="名前（カタカナ）を入力してください"
        id={"firstNameKana"}
        inputProps={{ ...register("firstNameKana") }}
        error={errors.firstNameKana?.message}
      />
      <Input
        label={"郵便番号(ハイフン不要)"}
        placeholder="郵便番号を入力してください"
        id={"zipcode"}
        inputProps={{ ...register("zipcode") }}
        error={errors.zipcode?.message}
      />
      <Button
        variant="purple"
        onClick={() => {
          const postalCode = getValues("zipcode")
          if (postalCode && !errors.zipcode) {
            getAddressbyPostalCode(postalCode, getAddress)
          }
        }}
      >
        住所を検索する
      </Button>
      <Input
        label={"都道府県"}
        placeholder="都道府県を入力してください"
        id={"province"}
        inputProps={{ ...register("province") }}
        error={errors.province?.message}
      />
      <Input
        label={"市区町村"}
        placeholder="市区町村を入力してください"
        id={"city"}
        inputProps={{ ...register("city") }}
        error={errors.city?.message}
      />
      <Input
        label={"番地"}
        placeholder="番地を入力してください"
        id={"address"}
        inputProps={{ ...register("address") }}
        error={errors.address?.message}
      />
      <Input
        label={"ビル名"}
        placeholder="ビル名を入力してください"
        id={"buildingName"}
        inputProps={{ ...register("buildingName") }}
        error={errors.buildingName?.message}
      />
      <Input
        label={"メールアドレス"}
        placeholder="メールアドレスを入力してください"
        id={"email"}
        inputProps={{ ...register("email") }}
        type="email"
        error={errors.email?.message}
        autoComplete="off"
      />
      <Input
        label={"メールアドレス(確認用)"}
        placeholder="メールアドレス(確認用)を入力してください"
        id={"confirmEmail"}
        inputProps={{ ...register("confirmEmail") }}
        type="email"
        error={errors.confirmEmail?.message}
        autoComplete="off"
      />
      <div style={{ width: "100%" }}>
        <Text sz="xxs" fw="bold">
          パスワード
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          パスワードは請求書の確認の際に必要です。
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・6文字以上で入力してください
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・一つ以上文字を含んでください
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・一つ以上数字を含んでください
        </Text>
        <Input
          label={""}
          placeholder="パスワードを入力してください"
          id={"password"}
          inputProps={{
            ...register("password"),
            onKeyUp: () => {
              trigger("password")
            },
          }}
          type="password"
          error={errors.password?.message}
          autoComplete="off"
        />
      </div>
      <Input
        label={"メインチャンネルの名前"}
        placeholder="メインチャンネルの名前を入力してください"
        id={"mainChannelName"}
        inputProps={{ ...register("mainChannelName") }}
        error={errors.mainChannelName?.message}
      />
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Input
            label={`YouTubeチャンネルのURL ${index + 1}`}
            placeholder="YouTubeチャンネルのURLを入力してください"
            id={`youtubeChannelUrl.${index}.url`}
            inputProps={{ ...register(`youtubeChannelUrl.${index}.url`) }}
            error={errors.youtubeChannelUrl?.[index]?.url?.message}
          />
          {index !== 0 && (
            <Button variant="purple" onClick={() => remove(index)}>
              - URLを削除する
            </Button>
          )}
        </Fragment>
      ))}
      <Button variant="purple" onClick={() => append({ url: "" })}>
        + URLを追加する
      </Button>
      <Input
        label={"YouTubeショートの投稿頻度(月)"}
        placeholder="YouTubeショートの投稿頻度を入力してください"
        id={"youtubeShortCount"}
        inputProps={{ ...register("youtubeShortCount") }}
        error={errors.youtubeShortCount?.message}
      />
      <Input
        label={"紹介者の名前"}
        placeholder="紹介者の名前を入力してください"
        id={"inviteName"}
        inputProps={{ ...register("inviteName") }}
        error={errors.inviteName?.message}
      />
      <div className={styles.agencyCheckBox}>
        <Text fw="bold">事務所に所属してますか？</Text>
        <input type="checkbox" {...register("agency")} />
      </div>
      <Input
        label={"平均再生回数(月)"}
        placeholder="平均再生回数を入力してください"
        id={"averageViewCounts"}
        inputProps={{ ...register("averageViewCounts") }}
        error={errors.averageViewCounts?.message}
      />
      <>
        <Text ta="left" fw="bold">
          アナリティクス再生回数画面(90日)
        </Text>
        <Text sz="xxs" color="red">
          ※「YT
          studio」を開く→下部「アナリティクス」→上部「コンテンツ」→「YouTubeショート」→ショートからの視聴回数→上部「90日」→スクリーンショット
        </Text>
      </>
      <Button variant="purple" onClick={handleButtonClick}>
        + 画像をアップロードする
      </Button>
      {errors.screenShot?.message && !selectedFile && (
        <Text sz="xxs" color="red" style={{ marginTop: "-20px" }}>
          {errors.screenShot?.message}
        </Text>
      )}
      {selectedFile && (
        <>
          <Text sz="xxs" style={{ marginTop: "-20px" }} color="green">
            画像をアップロードしました。
          </Text>
          <img
            src={URL.createObjectURL(selectedFile)}
            className={styles.previewImage}
            alt="screenshot"
          />
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept=".png, .jpg, .jpeg"
      />
      <Button variant={"yellow"} onClick={handleSubmit(handleRegister)}>
        契約可否確認
      </Button>
      <Button variant={"purple"} onClick={onPrevious}>
        前へ
      </Button>
    </>
  )
}

export default InfluencerContract
