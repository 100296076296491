import { Router } from "routes"
import { en_errors, ja_errors } from "./constant"
import postal_code, { AddressData } from "japan-postal-code"

export const getApp = () => {
  const subdomain = getSubdomain(window.location.hostname)

  if (subdomain) return { app: Router, domain: subdomain }

  return { app: Router, domain: "admin" }
}

export const getSubdomain = (location: string) => {
  const locationParts = location.split("-")
  let sliceTill = -2
  const isLocalHost = (locationParts.slice(-1)[0] = "localhost")
  if (isLocalHost) sliceTill = -1
  return locationParts.slice(0, sliceTill).join("") || "admin"
}

export const getAddressbyPostalCode = (
  postalCode: string,
  setAddress: (address: AddressResponseData) => void
) => {
  if (postalCode.length === 7) {
    postal_code.get(postalCode, (address: AddressData) => {
      const newAddress: AddressResponseData = {
        province: address.prefecture,
        street: address.street,
        city: address.city,
        area: address.area,
      }
      setAddress(newAddress)
    })
  }
}

export const getErrorMessage = (code: string) => {
  const errors = window.location.pathname.includes("en") ? en_errors : ja_errors
  const errorMessages: ErrorMessages = {
    "2001": errors.emailAlreadyInUse,
    "1002": errors.emailNotExist,
    "1003": errors.invalidPassword,
    "1004": errors.passwordNotMatch,
    "1005": errors.emailRequired,
    "1006": errors.invalidEmail,
    "1007": errors.confirmEmailNotMatch,
    "1008": errors.passwordRequired,
    "1009": errors.confirmPasswordNotMatch,
    "1010": errors.nameRequired,
    "1011": errors.address,
    "1012": errors.phone,
    "1013": errors.invalidPhone,
    "1014": errors.postalCode,
    "1015": errors.userDeleted,
    "1016": errors.userDeclined,
    "1021": errors.noDocumentFound,
    "1087": errors.noDocumentFound,
    "This influencer is not contracted": errors.notcontracted,
    default: errors.unknownError,
  }
  return errorMessages[code] || errorMessages["default"]
}

export const downloadCSVfile = (data: BlobPart) => {
  const blobOptions = { type: "text/csv;charset=utf-8;" }
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const blobData = [bom, data]

  const blob = new Blob(blobData, blobOptions)

  const url = window.URL.createObjectURL(blob)
  // const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a")
  link.href = url
  const fileName = `influencer-${new Date().toLocaleString()}.csv`
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const formatDateToYYYYMMDD = (date: Date, seprator: "/" | "-" = "/") => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")

  return `${year}${seprator}${month}${seprator}${day}`
}

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
