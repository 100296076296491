import useSWR from "swr"
import { useAPI } from "./useAPI"
import { formatDateToYYYYMMDD } from "utils/helper"
import { getCookies, removeCookies } from "utils/cookies"
import axios, { isAxiosError } from "axios"

const usePaymentReport = (
  userId: string,
  startDate: string = "",
  endDate: string = ""
) => {
  const { get } = useAPI()
  const isEnglish = window.location.href.includes("/en/")

  if (startDate && !endDate) {
    const nextDay = new Date()
    nextDay.setDate(nextDay.getDate() + 1)
    endDate = formatDateToYYYYMMDD(nextDay, "-")
  }

  const buildUrl = (
    endpoint: string,
    startDate?: string,
    endDate?: string
  ): string => {
    let url = endpoint
    const params = new URLSearchParams()

    if (startDate) {
      params.append("startDate", startDate)
    }
    if (endDate) {
      params.append("endDate", endDate)
    }
    params.append("userType", isEnglish ? "en" : "ja")

    const paramString = params.toString()
    if (paramString) {
      url += `?${paramString}`
    }

    return url
  }

  const {
    data: paymentReportData,
    error,
    isLoading,
  } = useSWR<PaymentReportApiResponse>(
    userId
      ? buildUrl(`/admin/payment/${userId}/report`, startDate, endDate)
      : "",
    get,
    {
      revalidateOnFocus: false,
      errorRetryCount: 2,
    }
  )

  const getPdf = async (url: string) => {
    try {
      const accessToken = getCookies("accessToken")
      const headers = {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${accessToken}`,
      }
      const document = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        { responseType: "blob", headers }
      )
      return document.data
    } catch (e: any) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          removeCookies("accessToken")
          window.location.href = "/login"
        }
      }
    }
  }

  const {
    data: invoicePdfData,
    error: invoicePdfError,
    isLoading: invoicePdfLoading,
  } = useSWR<any>(
    userId
      ? buildUrl(`admin/payment/payOut/${userId}/pdf`, startDate, endDate)
      : "",
    getPdf,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  )

  return {
    paymentReportData,
    invoicePdfData,
    invoicePdfLoading,
    invoicePdfError,
    isLoading,
    error,
  }
}

export default usePaymentReport
