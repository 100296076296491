import React from "react"
import Text from "common/components/atoms/Text"
import styles from "../Payout/Payout.module.scss"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const AccountRegistrationCompleted = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isEnglish = window.location.href.includes("/en/")

  return (
    <div className={styles.accountRegistrationCompleted}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            <Text>{t("payout.AccountRegistrationCompleted")}</Text>
          </div>
        </div>
        <div className={styles.content}>
          <Text className={styles.title}>
            {t("payout.AccountRegistrationCompletedMessage")}
          </Text>
          <p
            className={styles.returnToLogin}
            onClick={() =>
              navigate(isEnglish ? "/en/payout/bank" : "/payout/bank")
            }
          >
            {t("payout.ReturnToBankPage")}
          </p>
        </div>
      </div>
    </div>
  )
}

export default AccountRegistrationCompleted
