import React from "react"
import styles from "./TextCarousels.module.scss"
import { useNavigate } from "react-router-dom"

interface TextCarousel {
  month: string
  monthString: string
  sales: number
}

interface TextCarouselProps {
  TextCarousels: TextCarousel
  // currentMonthIndex: number;
  handlePreviousMonth: () => void
  handleNextMonth: () => void
  handlePreviousText: string
  handleNextText: string
  linkTo: string
  // linkToHref: string
}

const TextCarousel: React.FC<TextCarouselProps> = ({
  TextCarousels,
  // currentMonthIndex,
  handlePreviousMonth,
  handlePreviousText,
  handleNextMonth,
  handleNextText,
  linkTo,
  // linkToHref
}) => {
  const navigate = useNavigate()

  const formatCurrency = (value: number) => {
    const isEnglish = window.location.href.includes("/en/")
    const currencySymbol = isEnglish ? "$" : "¥"
    const formattedValue = currencySymbol + Math.round(value).toLocaleString()

    return formattedValue
  }

  return (
    <div className={styles.monthlyCardWrapper}>
      <div className={styles.flexSpaceBetween}>
        <span className={styles.textLeft} onClick={handlePreviousMonth}>
          {handlePreviousText}&#60;
        </span>
        <span className={styles.textMiddle}>{TextCarousels?.monthString}</span>
        <span className={styles.textRight} onClick={handleNextMonth}>
          &#62;{handleNextText}
        </span>
      </div>
      <div className={styles.flexBottomCenter}>
        <div>
          <span>{formatCurrency(TextCarousels?.sales)}</span>
        </div>
        <span
        // className={`${styles.bottomText}`}
        // onClick={() => navigate(linkToHref)}
        >
          {linkTo}
        </span>
      </div>
    </div>
  )
}

export default TextCarousel
