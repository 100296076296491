import useSWRMutation from "swr/mutation";
import { useAPI } from "./useAPI";
import useAuthStore from "store/authStore";

const useLogin = () => {
  const userType = useAuthStore((state) => state.userType);
  const { put } = useAPI();

  const user = userType === "influencer" ? "influencer" : "admin";

  const sendLoginRequest = async (url: string, { arg }: { arg: UserLogin }) => {
    const response: AuthTokens = await put(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `/${user}/auth/login`,
    sendLoginRequest
  );

  return {
    onLogin: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useLogin;
