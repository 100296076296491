// @ts-nocheck
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styles from "./Invoice.module.scss"
import { createColumnHelper } from "@tanstack/react-table"
import Table from "common/components/atoms/Table"
import useAdminPaymentReport from "common/hooks/useAdminPaymentReport"
import Text from "common/components/atoms/Text"
import { formatDateToYYYYMMDD, getErrorMessage } from "utils/helper"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"

const Invoice = () => {
  const params = useParams()
  const id = params?.id
  const columnHelper = createColumnHelper<any>()

  const [invoiceData, setInvoiceData] = useState<ReportResult>()
  const [dateRange, setDateRange] = useState(() => {
    const now = new Date()
    const start = new Date(now.getFullYear(), now.getMonth(), 1)
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    return {
      startDate: formatDateToYYYYMMDD(start, "-"),
      endDate: formatDateToYYYYMMDD(end, "-"),
    }
  })

  const {
    paymentReportData,
    invoicePdfData,
    invoicePdfLoading,
    isLoading,
    error,
  } = useAdminPaymentReport(id || "", dateRange.startDate, dateRange.endDate)
  const setError = useError((state) => state.setError)

  useEffect(() => {
    if (paymentReportData?.success) {
      setInvoiceData(paymentReportData.result[0])
    }
    if (error) {
      setError(getErrorMessage(error.subStatusCode))
    }
  }, [paymentReportData, error])

  const handlePreviousMonth = () => {
    const now = new Date(dateRange.startDate)
    const start = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const end = new Date(now.getFullYear(), now.getMonth(), 0)
    setDateRange({
      startDate: formatDateToYYYYMMDD(start, "-"),
      endDate: formatDateToYYYYMMDD(end, "-"),
    })
  }

  const handleNextMonth = () => {
    const now = new Date(dateRange.startDate)
    const start = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    const end = new Date(now.getFullYear(), now.getMonth() + 2, 0)
    setDateRange({
      startDate: formatDateToYYYYMMDD(start, "-"),
      endDate: formatDateToYYYYMMDD(end, "-"),
    })
  }

  const openPdfPreview = () => {
    if (invoicePdfData) {
      const pdfBlob = new Blob([invoicePdfData], { type: "application/pdf" })
      const downloadUrl = URL.createObjectURL(pdfBlob)
      window.open(downloadUrl, "_blank")
      return downloadUrl
    } else {
      setError(getErrorMessage("1087"))
    }
  }

  const columns = [
    columnHelper.accessor("videoId", {
      header: () => "動画ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("musicTitle", {
      header: () => "楽曲名",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("totalViews", {
      header: () => "再生回数",
      cell: (info) => formatViews(info.renderValue()) || 0,
    }),
    columnHelper.accessor("totalPayment", {
      header: () => "金額",
      cell: (info) => currencySymbol + formatCurrency(info.renderValue()) || 0,
    }),
  ]

  const formatCurrency = (value: number) => {
    return value?.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    })
  }
  const totalAmount = formatCurrency(invoiceData?.totalAmount || 0)
  const currentBalance = formatCurrency(invoiceData?.currentBalance || 0)
  const transferAmount = formatCurrency(invoiceData?.transferAmount || 0)
  const unitPrice = formatCurrency(invoiceData?.unitPrice || 0)
  const isEnglish = window.location.href.includes("/en/")
  const currencySymbol = isEnglish ? "$" : "¥"

  const formatViews = (viewsCount = 0) => {
    return Intl.NumberFormat(isEnglish ? "en-US" : "ja-JP", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(viewsCount)
  }

  return (
    <div className={styles.container}>
      <div className={styles.TopCardwrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.dropDrownWrapper}>
            <span className={styles.textLeft} onClick={handlePreviousMonth}>
              {"次へ "}&#60;
            </span>
            <span className={styles.textMiddle}>
              {new Date(dateRange.startDate).toLocaleString("ja-JP", {
                month: "long",
              })}
            </span>
            <span className={styles.textRight} onClick={handleNextMonth}>
              &#62;{" 前へ"}
            </span>
          </div>
        </div>
        <div className={styles.columnItemWrapper}>
          <div className={styles.columnItem}>
            <p className={styles.columnItemValue}>
              {currencySymbol}
              {totalAmount}
            </p>
            <p className={styles.columnItemTitle}>合計金額</p>
          </div>
          <div className={styles.columnItem}>
            <p className={styles.columnItemValue}>
              {formatViews(invoiceData?.totalViews)}
            </p>
            <p className={styles.columnItemTitle}>総再生回数</p>
          </div>
          <div className={styles.columnItem}>
            <p className={styles.columnItemValue}>
              {currencySymbol}
              {currentBalance}
            </p>
            <p className={styles.columnItemTitle}>未払い金額</p>
          </div>
          <div className={styles.columnItem}>
            <p className={styles.columnItemValue}>
              {currencySymbol}
              {transferAmount}
            </p>
            <p className={styles.columnItemTitle}>振込予定金額</p>
          </div>
          <div className={styles.columnItem}>
            <p className={styles.columnItemValue}>
              {currencySymbol}
              {unitPrice}
            </p>
            <p className={styles.columnItemTitle}>単価(1再生当たり)</p>
          </div>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        {isLoading && <Loader />}
        <Table
          onFilter={() => {}}
          tableTitle="月の支払額明細"
          data={invoiceData?.paymentReport || []}
          columns={columns}
          onDownloadPdf={openPdfPreview}
        />
      </div>
    </div>
  )
}

export default Invoice
