import React from 'react';
import Loader from '../Loader';

interface TableProps {
  data: { [key: string]: string }[];
  headers: string[];
  isLoading?: boolean
}

const Table: React.FC<TableProps> = ({ data, headers, isLoading }) => {
  return (
    <table>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!isLoading ? (
          data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                {Object.keys(item).map((key, columnIndex) => (
                  <td key={columnIndex}>{item[key]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td>No records</td>
            </tr>
          )
        ) : <Loader />}
      </tbody>
    </table>
  );
};

export default Table;
