import React from "react"
import useSWR from "swr"
import { useAPI } from "./useAPI"
import useAuthStore from "store/authStore"

const usePayoutReport = (
  nextOffset: number = 0,
  startDate: string,
  endDate: string
) => {
  const userType = useAuthStore((state) => state.userType)
  const { get } = useAPI()
  const isEnglish = window.location.href.includes("/en/")

  const {
    data: payoutReportData,
    error,
    mutate,
    isLoading,
  } = useSWR<PayoutReportResponse>(
    userType === "influencer"
      ? isEnglish
        ? `/influencer/en/payout/payment/report/?limit=50&offset=${
            nextOffset || 0
          }&startDate=${startDate}&endDate=${endDate}`
        : `/influencer/payout/payment/report/?limit=50&offset=${
            nextOffset || 0
          }&startDate=${startDate}&endDate=${endDate}`
      : "",
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    payoutReportData,
    isLoading,
    error,
    mutatePayoutReport: mutate,
  }
}

export default usePayoutReport
