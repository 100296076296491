import React from "react"
import styles from "./GridCard.module.scss"

interface CardItemProps {
  title: string
  value: number | string
  varientText?: boolean
  isCurrency?: boolean
  precision?: number
}

const CardItem: React.FC<CardItemProps> = ({
  title,
  value,
  varientText,
  isCurrency = true,
  precision,
}) => {
  const isEnglish = window.location.href.includes("/en/")
  const formatCurrency = (value: number) => {
    const isEnglish = window.location.href.includes("/en/")
    const currencySymbol = isEnglish ? "$" : "¥"
    const formattedValue =
      precision !== undefined
        ? currencySymbol + value.toFixed(precision).toLocaleString()
        : currencySymbol + Math.round(value).toLocaleString()

    return formattedValue
  }

  // const formatViews = (value: number | string) => {
  //   const numericValue = typeof value === "number" ? value : parseInt(value, 10);
  //   const isEnglish = window.location.href.includes("/en/");
  //   const suffix = isEnglish ? "" : "回";

  //   const kFormatter = (num: number) => {
  //     if (Math.abs(num) > 999) {
  //       const formatted = (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(0);
  //       return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "k";
  //     } else {
  //       return Math.sign(num) * Math.abs(num);
  //     }
  //   };
  //   return kFormatter(numericValue) + suffix;
  // };

  const formatViews = (viewsCount = 0) => {
    const suffix = isEnglish ? "" : " 回"
    return (
      Intl.NumberFormat(isEnglish ? "en-US" : "ja-JP", {
        notation: "compact",
        maximumFractionDigits: 1,
      }).format(viewsCount) + suffix
    )
  }

  const formattedValue =
    typeof value === "number"
      ? isCurrency
        ? formatCurrency(value)
        : formatViews(value)
      : value

  return (
    <div
      className={styles.gridCardItem}
      style={varientText ? { gap: 0 } : undefined}
    >
      <p className={varientText ? styles.varient : ""}>{title}</p>
      <p>{formattedValue}</p>
    </div>
  )
}

export default CardItem
