import React, { useState, useEffect } from "react"
import Button from "common/components/atoms/Button"
import styles from "../Payout/Payout.module.scss"
import Input from "common/components/atoms/Input"

import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import usePayoutBank from "common/hooks/usePayoutBank"
import useEditAccountDetails from "common/hooks/useEditAccountDetails"
import { useNavigate } from "react-router-dom"
import RadioGroup from "common/components/molecules/RadioGroup/RadioGroup"
import Text from "common/components/atoms/Text"
import { useTranslation } from "react-i18next"
import Loader from "common/components/atoms/Loader"

interface AccountRegistrationProps {
  isEdit?: boolean
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
}

const AccountRegistration = ({
  isEdit,
  setIsEdit,
}: AccountRegistrationProps) => {
  const { onAccountUpdate, isLoading } = useEditAccountDetails()

  const [selectedBank, setSelectedBank] = useState("")
  const [bankDetails, setBankDetails] = useState<PayoutBank>()
  const navigate = useNavigate()
  const { payoutBank } = usePayoutBank()
  const { t } = useTranslation()
  const isEnglish = window.location.href.includes("/en/")

  const fetchBankDetails = () => {
    // fetch bank details
    if (payoutBank?.success) {
      setBankDetails(payoutBank.result)
    }
  }

  useEffect(() => {
    fetchBankDetails()
  }, [isEdit == true])

  const validationSchema = yup.object().shape({
    bankName: yup.string().required(t("payout.RequiredField")),
    bankBranch: yup.string().required(t("payout.RequiredField")),
    bankNo: yup.string().required(t("payout.RequiredField")),
    bankType: yup.string().required(t("payout.RequiredField")),
    bankAccountHolder: yup.string().required(t("payout.RequiredField")),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bankName: bankDetails?.bankName || "",
      bankBranch: bankDetails?.bankBranch || "",
      bankNo: bankDetails?.bankNo || "",
      bankType: bankDetails?.bankType || "saving",
      bankAccountHolder: bankDetails?.bankAccountHolder || "",
    },
  })

  const handleChange = (event: { target: { value: string } }) => {
    let bankType = event.target.value
    setValue("bankType", bankType)
    setSelectedBank(event.target.value)
  }

  useEffect(() => {
    if (bankDetails) {
      setValue("bankName", bankDetails?.bankName)
      setValue("bankBranch", bankDetails?.bankBranch)
      setValue("bankNo", bankDetails?.bankNo)
      setValue("bankType", bankDetails?.bankType)
      setValue("bankAccountHolder", bankDetails?.bankAccountHolder)
    }
  }, [bankDetails])

  const handleAccountUpdate = (data: Partial<PayoutBank>) => {
    onAccountUpdate(data)
      .then((res) => {
        console.log(res)
        navigate(isEnglish ? "/en/payout/bank/confirm" : "/payout/bank/confirm")
      })
      .catch(() => {
        console.log("error")
      })
  }

  const bankTypeOptions = [
    { value: "current", label: t("payout.current") },
    { value: "saving", label: t("payout.saving") },
  ]

  return (
    <div className={styles.accountRegistration}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <Text>{t("payout.AccountRegistrationTitle")}</Text>
        </div>
        <div className={styles.Inputwrapper}>
          <Input
            label={t("payout.FinancialInstitutionName")}
            placeholder={t("payout.SelectFinancialInstitution")}
            id="bankName"
            type="string"
            inputProps={{ ...register("bankName") }}
            error={errors?.bankName?.message}
            /* onClick={() => setCurrentModal(ModalType.RadioButton)} */
          />
          <Input
            label={t("payout.BranchName")}
            placeholder={t("payout.EnterBranchName")}
            id="bankBranch"
            inputProps={{ ...register("bankBranch") }}
            type="string"
            error={errors?.bankBranch?.message}
          />
          <RadioGroup
            title={t("payout.AccountType")}
            options={bankTypeOptions}
            register={register("bankType")}
          />
          <div>
            <Text className={styles.smallText}>
              {t("payout.accountNumber")}
            </Text>
            <Input
              label={t("payout.accountNumberInputMessage")}
              placeholder={t("payout.EnterAccountNumber")}
              id="bankNo"
              inputProps={{ ...register("bankNo") }}
              type="string"
              error={errors?.bankNo?.message}
            />
          </div>
          <Input
            label={t("payout.AccountHolder")}
            placeholder={t("payout.EnterAccountNameKana")}
            id="bankAccountHolder"
            inputProps={{ ...register("bankAccountHolder") }}
            type="string"
            error={errors?.bankAccountHolder?.message}
          />
          <div className={styles.btnWrapper}>
            <Button
              onClick={handleSubmit(handleAccountUpdate)}
              disabled={isLoading}
            >
              {isLoading && <Loader />}
              {t("payout.GoToConfirmationScreen")}
            </Button>
            <Button onClick={() => navigate(-1)}>{t("payout.GoBack")}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountRegistration
