import React from "react";
import Text from "common/components/atoms/Text";
import Title from "common/components/atoms/Title";
import Button from "common/components/atoms/Button";
import { useNavigate } from "react-router-dom";

const RegistrationComplete = () => {
  const navigate = useNavigate();

  return (
    <>
      <Title order={5} color="purple" fw="bold" ta="center" lineHeight="1">
        {`ご登録`}
      </Title>
      <Title order={5} color="purple" fw="bold" ta="center" lineHeight="1">
        {`ありがとうございます。`}
      </Title>
      <Text fw="bold">
        {`入力頂きました内容を確認いたしますので、少々お待ちください。\n土日祝日を除く2営業日以内に結果のご連絡をさせて頂きます。`}
      </Text>
      <Button variant="yellow" onClick={() => navigate("/login")}>
        ログインへ
      </Button>
    </>
  );
};

export default RegistrationComplete;
