import React, { useEffect, useState } from "react"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import Button from "common/components/atoms/Button"
import styles from "./ContractApplication.module.scss"
import useRevenue from "common/hooks/useRevenue"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"
import { useTranslation } from "react-i18next"
import useAuthStore from "store/authStore"

interface ApplicationProps {
  onProceed: () => void
  onPrevious: () => void
}

const ContractApplication = ({ onProceed, onPrevious }: ApplicationProps) => {
  const { revenueData, isLoading: revenueLoading, error } = useRevenue()
  const [estimatedRevenue, setRevenue] = useState(0)
  const setError = useError((state) => state.setError)
  const isLoading = useAuthStore((state) => state.loading)
  const { t } = useTranslation()
  const averageViewCounts =
    useAuthStore((state) => state.userRegister.averageViewCounts) || 1

  useEffect(() => {
    if (revenueData)
      setRevenue(revenueData.data.result.expectedRevenue * averageViewCounts)
    if (error) setError(error)
  }, [revenueData, error, estimatedRevenue, setError, averageViewCounts])

  return (
    <>
      <Title order={5} color="purple" fw="bold">
        契約申請
      </Title>
      <div className={styles.revenueBox}>
        <Text ta="center">1ヶ月当たりの収益予測</Text>
        {revenueLoading ? (
          <Loader />
        ) : (
          <Text sz="lg" fw="bold" ta="center">
            {estimatedRevenue}円
          </Text>
        )}
      </div>
      <Text fw="bold" sz="xs" ta="center" color="red">
        ※ご契約の連絡で公式LINEの登録は必須となります
      </Text>
      <img
        src="./assets/icons/contract-sign.png"
        alt="qr-code"
        className={styles.qrCode}
      />
      <a
        href="https://lin.ee/QT5TLA3"
        target="_blank"
        rel="noreferrer"
        className={styles.lineLink}
      >
        公式LINEはこちら
      </a>
      <Button variant="yellow" onClick={onProceed} disabled={isLoading}>
        {isLoading && <Loader />}申請へ進む
      </Button>
      <Button variant={"purple"} onClick={onPrevious} disabled={isLoading}>
        前へ
      </Button>
    </>
  )
}

export default ContractApplication
