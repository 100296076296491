import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"
import useAuthStore from "store/authStore"

const useEditAccountDetails = () => {
  const userType = useAuthStore((state) => state.userType)
  const { put } = useAPI()
  const isEnglish = window.location.href.includes("/en/")

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<PayoutBank> }
  ) => {
    const response = await put(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    userType === "influencer"
      ? isEnglish
        ? `/influencer/en/payout/bank`
        : `/influencer/payout/bank`
      : "",
    sendUpdateRequest
  )

  return {
    onAccountUpdate: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useEditAccountDetails
