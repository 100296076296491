import React, { FC, InputHTMLAttributes, useState } from "react";
import styles from "./Input.module.scss";
import Text from "../Text";
import EyeSlash from "../../../assets/icons/not-visible.svg"
import Eye from "../../../assets/icons/visible.svg"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  error?: string;
}

const Input: FC<InputProps> = ({ label, type, id, inputProps, error, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  let inputContent = null

  switch (type) {
    case "password":
      inputContent = (
        <div className={`${styles.passwordWrapper}`}>
          <input
            type={showPassword ? "text" : type}
            className={`${styles.input}`}
            {...inputProps}
            {...props}
          />
          <div
            className={styles.visibilityToggle}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <img src={Eye} alt="pass-icon" />
            ) : (
              <img src={EyeSlash} alt="eyeslash icon" />
            )}
          </div>
        </div>
      )
      break
    default:
      inputContent = (
        <input className={`${styles.input}`} {...inputProps} {...props} />
      )
      break;
  }

  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={id} className={`${styles.label}`}>
        {label}
      </label>
      {inputContent}
      {error && (
        <Text sz="xxs" color="red">
          {error}
        </Text>
      )}
    </div>
  );
};

export default Input;
