import React from "react"
import Payout from "../../../common/components/templates/PayoutModals/Payout/Payout"
import { AccountRegistrationConfirmation } from "common/components/templates/PayoutModals/AccountRegistrationConfirmation"

const ConfirmBankDetails = () => {
  return (
    <Payout>
      <AccountRegistrationConfirmation />
    </Payout>
  )
}

export default ConfirmBankDetails
