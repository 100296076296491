// @ts-nocheck
import React, { useEffect, useState } from "react"
import styles from "../Payout/Payout.module.scss"
import { useNavigate } from "react-router-dom"
import CardItem from "common/components/molecules/GridCard/GridCard"
import TextCarousel from "common/components/molecules/TextCarousels/TextCarousels"
import Table from "common/components/atoms/cleanTable/Table"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import usePayoutReport from "common/hooks/usePayoutReport"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"

const ViewMonthlyPaymentAmount = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let nowStartDate = dayjs().startOf("month").format("YYYY-MM-DD")
  let nowEndDate = dayjs().endOf("month").format("YYYY-MM-DD")

  const [payoutData, setPayoutData] = useState<PayoutReportResponse>()
  const [monthData, setMonthData] = useState<TextCarousel>({
    month: "",
    monthString: "",
    sales: 0,
  })
  const [tableData, setTableData] = useState<
    { videoId: string; musicTitle: string; totalViews: string; total: string }[]
  >([])
  const [nextOffset, setNextOffset] = useState(0)
  const [startDate, setStartDate] = useState(nowStartDate)
  const [endDate, setEndDate] = useState(nowEndDate)
  const isEnglish = window.location.href.includes("/en/")
  const currencySymbol = isEnglish ? "$" : "¥"
  const setError = useError((state) => state.setError)

  const {
    payoutReportData: newData,
    isLoading,
    error,
    mutatePayoutReport,
  } = usePayoutReport(nextOffset, startDate, endDate)

  console.log("newData", newData)

  useEffect(() => {
    if (newData?.result.length === 0) {
      setError(isEnglish ? "No data found" : "何もデータが見つかりませんでした")
      setStartDate(nowStartDate)
      setEndDate(nowEndDate)
    }

    if ((newData?.result?.length ?? 0) > 0) {
      setPayoutData(newData)
    }
    console.log(dayjs(), "newData", newData)
  }, [newData])

  useEffect(() => {
    if ((payoutData?.result.length ?? 0) > 0) {
      const currentMonth = dayjs(payoutData?.result[0]?.date).format("MM")
      setMonthData({
        ...monthData,
        month: currentMonth,
        monthString: isEnglish
          ? dayjs(payoutData?.result[0]?.date).format("MMMM")
          : dayjs(payoutData?.result[0]?.date)
              .locale("ja", {
                months: [
                  "1月",
                  "2月",
                  "3月",
                  "4月",
                  "5月",
                  "6月",
                  "7月",
                  "8月",
                  "9月",
                  "10月",
                  "11月",
                  "12月",
                ],
              })
              .format("MMMM"),

        sales: payoutData?.result[0]?.amount || 0,
      })

      console.log(t(monthData.monthString))

      const paymentReport = payoutData?.result[0]?.paymentReport
      if (paymentReport) {
        setTableData(
          paymentReport.map((ele) => ({
            videoId: ele["videoId"],
            musicTitle: ele["musicTitle"],
            totalViews: ele["totalViews"].toLocaleString(),
            total: currencySymbol + Math.round(ele["total"]).toLocaleString(),
          }))
        )
      } else {
        setTableData([])
      }
    }
  }, [payoutData])

  const handleNextMonth = () => {
    const startDateTime = dayjs(startDate)
    setStartDate(
      startDateTime.add(1, "month").startOf("month").format("YYYY-MM-DD")
    )
    const endDateTime = dayjs(endDate)
    setEndDate(endDateTime.add(1, "month").endOf("month").format("YYYY-MM-DD"))
  }

  const handlePreviousMonth = () => {
    const startDateTime = dayjs(startDate)
    setStartDate(
      startDateTime.add(-1, "month").startOf("month").format("YYYY-MM-DD")
    )
    const endDateTime = dayjs(endDate)
    setEndDate(endDateTime.add(-1, "month").endOf("month").format("YYYY-MM-DD"))
  }

  console.log("startDate", startDate, "endDate", endDate)

  return (
    <div className={styles.monthlyCardContainer}>
      <div
        className={styles.fullWidthTextLeft}
        onClick={() => {
          navigate(isEnglish ? "/en/payout" : "/payout")
        }}
      >
        <p>{t("payout.GoBack")}</p>
      </div>

      <TextCarousel
        TextCarousels={monthData}
        // currentMonthIndex={currentMonthIndex}
        handlePreviousMonth={handlePreviousMonth}
        handlePreviousText={t("payout.PreviousMonth")}
        handleNextMonth={handleNextMonth}
        handleNextText={t("payout.NextMonth")}
        linkTo={t("payout.RevenueAmount")}
        // linkToHref={"/payout/bank"}
      />

      <div className={styles.gridCardContainer}>
        <CardItem
          title={t("payout.TotalViews")}
          value={payoutData?.result[0].totalViews || 0}
          isCurrency={false}
        />

        <CardItem
          title={t("payout.CostPerView")}
          value={payoutData?.result[0].unitPrice || 0}
          precision={4}
        />
      </div>

      <div className={styles.listTableContainer}>
        <Table
          data={tableData.length > 0 ? tableData : []}
          headers={[
            t("payout.VideoID"),
            t("payout.SongName"),
            t("payout.NumberOfViews"),
            t("payout.AmountExcludingTax"),
          ]}
          isLoading={isLoading}
        />
        {/* {isLoading && <Loader />} */}
      </div>
    </div>
  )
}

export default ViewMonthlyPaymentAmount
