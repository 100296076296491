import React, { useEffect } from "react"
import styles from "./Payout.module.scss"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom";

const Payout = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const isEnglish = location.pathname.includes('/en');
    if (isEnglish) {
      i18n.changeLanguage('en');
      console.log('ENLogin');
    } else {
      i18n.changeLanguage('ja');
      console.log('JPLogin');
    }
  }, [location.pathname, i18n]);

  return (
   <div className={styles.payoutWrapper}>
      <div className={styles.payOutContainer}>
        {children}
      </div>
    </div>
  )
}

export default Payout
