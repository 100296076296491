import React, { FC, ButtonHTMLAttributes } from "react"
import styles from "./Button.module.scss"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string
  iconPosition?: "left" | "right"
  variant?: "yellow" | "purple" | "grey"
  maxWidth?: number
}

const Button: FC<ButtonProps> = ({
  icon,
  iconPosition = "null",
  variant = "yellow",
  maxWidth,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`${styles.button} ${styles[variant]}`}
      style={{ maxWidth: `${maxWidth}px`, ...props?.style }}
    >
      {iconPosition === "left" && <img src={icon} alt="left-icon" />}
      {children}
      {iconPosition === "right" && <img src={icon} alt="right-icon" />}
    </button>
  )
}

export default Button
