import React from "react"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import Button from "common/components/atoms/Button"
import styles from "./UsageDocumentInfo.module.scss"

interface DocumentInfoProps {
  onProceed: () => void
  onPrevious: () => void
}

const UsageDocumentInfo = ({ onProceed }: DocumentInfoProps) => {
  return (
    <>
      <Title order={5} color="purple" fw="bold" ta="center">
        YouTube shorts動画 BGMサービス
      </Title>
      <Text fw="bold" ta="center">
        {`💡BGMサービスの特徴💡`}
      </Text>
      <div className={styles.description}>
        <li>費用は一切いただきません！</li>
        <li>広告収益＋BGM収益の実現！</li>
        <li>有名クリエイター様100名以上がご利用中！</li>
      </div>
      <div className={styles.buttonsContainer}>
        <Button variant="purple" onClick={onProceed}>
          申請へ進む
        </Button>
        <a
          href="./assets/document/contract-doc.pdf"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          サービス詳細はこちら
        </a>
      </div>
    </>
  )
}

export default UsageDocumentInfo
