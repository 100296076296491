import Title from "common/components/atoms/Title"
import Button from "common/components/atoms/Button"
import OrinasLogo from "common/assets/images/logo-orinas.png"
import styles from "./UsageDocumentInfo.module.scss"

interface DocumentInfoProps {
  onProceed: () => void
  onPrevious: () => void
}

const UsageDocumentInfo = ({ onProceed }: DocumentInfoProps) => {
  return (
    <>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={OrinasLogo} alt="" />
      </div>
      <Title
        className={styles.titleText}
        order={5}
        color="purple"
        fw="bold"
        ta="center"
      >
        YouTube Shorts Monetization
      </Title>
      <div className={styles.description}>
        <li>Get started completely free of charge.</li>
        <li>Increase profits beyond advertising revenue.</li>
        <li>Choose background music of many genres.</li>
      </div>
      <div className={styles.buttonsContainer}>
        <Button variant="purple" onClick={onProceed}>
          Register Now
        </Button>
        <a
          href="../assets/document/contract-doc-en.pdf"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          Service Info
        </a>
      </div>
    </>
  )
}

export default UsageDocumentInfo
