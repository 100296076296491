import React from "react"
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import Text from "../Text"
import styles from "./Table.module.scss"
import Button from "../Button"

const Table = ({
  data,
  columns,
  onFilter,
  tableTitle,
  isLoading,
  onNext,
  onPrev,
  onDownloadPdf,
}: TableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={styles["table-container"]}>
      <div className={styles["title-container"]}>
        {tableTitle && (
          <Text fw="bold" sz={"sm"} className={styles.tableTitle}>
            {tableTitle}
          </Text>
        )}
        {/* {onFilter && (
          <div className={styles["filter-button"]} onClick={onFilter}>
            <Text sz="xxs" fw="bold">
              絞り込み
            </Text>
            <img src={"./assets/icons/filter-icon.svg"} alt="React Logo" />
          </div>
        )} */}
        {onDownloadPdf && (
          <div style={{ maxWidth: "225px", width: "100%" }}>
            <Button style={{ marginTop: 0 }} onClick={onDownloadPdf}>
              PDFをダウンロード
            </Button>
          </div>
        )}
      </div>
      <table>
        <thead>
          {table.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers?.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.map((row) => (
            <tr key={row.id}>
              {row.getAllCells()?.map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {table.getRowModel().rows.length ? (
        <div className={styles.paginationButton}>
          <button
            onClick={onPrev}
            className={styles.loadMoreButton}
            disabled={!onPrev}
          >
            前へ
          </button>
          <button
            onClick={onNext}
            className={styles.loadMoreButton}
            disabled={!onNext}
          >
            次へ
          </button>
        </div>
      ) : (
        <Text fw="bold" ta="center">
          データがありません
        </Text>
      )}
    </div>
  )
}

export default Table
