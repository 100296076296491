import React from "react"
import styles from "./Navbar.module.scss"
import { ReactComponent as DropdownArrow } from "../../../assets/icons/dropdown.svg"
import { ReactComponent as MobileDropdownArrow } from "../../../assets/icons/dropdown-light.svg"
import { removeCookies } from "utils/cookies"
import useAuthStore from "store/authStore"
import { useAPI } from "common/hooks/useAPI"
import useInfluencersStore from "store/influencersStore"
import { useNavigate } from "react-router-dom"

const Navbar = () => {
  const { logout, userType } = useAuthStore((state) => state)
  const { put } = useAPI()
  const currentInfluencer = useInfluencersStore(
    (state) => state.currentInfluencer
  )

  const navigate = useNavigate()
  const menuTitle =
    userType === "admin"
      ? "管理者"
      : `${currentInfluencer?.familyName || ""} 
      ${currentInfluencer?.firstName || ""}`

  const onLogout = () => {
    put(`/${userType}/auth/logout`).then(() => {
      removeCookies("accessToken")
      removeCookies("refreshToken")
      logout()
      navigate("/login")
    })
  }

  return (
    <nav className={styles.nav}>
      <h3 onClick={() => navigate("/")}>
        {userType === "admin" ? "ダッシュボード" : "オリナス契約ツール"}
      </h3>
      <menu className={styles.menu}>
        {menuTitle}
        <DropdownArrow />
      </menu>
      <menu className={styles.mobileMenu}>
        <div>
          <h3>{menuTitle}</h3>
          <MobileDropdownArrow />
        </div>
      </menu>
      <ul className={styles.dropdownMenu}>
        {userType !== "admin" && (
          <>
            <li onClick={() => navigate("/edit-profile")}>アカウントを編集</li>
            {/* <li onClick={() => navigate("/payout")}>収益確認</li> */}
            {/* <li onClick={() => navigate("/payout/bank")}>口座情報</li> */}
            <li onClick={() => navigate("/change-password")}>
              パスワードを変更
            </li>
          </>
        )}
        <li onClick={onLogout}>ログアウト</li>
      </ul>
    </nav>
  )
}

export default Navbar
