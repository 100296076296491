import useSWRMutation from "swr/mutation";
import { useAPI } from "./useAPI";

const useRegister = () => {
  const { post } = useAPI();

  const sendRegisterRequest = async (
    url: string,
    { arg }: { arg: RegisterProps }
  ) => {
    const response: { success: boolean } = await post(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `${process.env.REACT_APP_API_BASE_URL}/influencer/auth/register`,
    sendRegisterRequest
  );

  return {
    onRegister: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useRegister;
