import React from 'react'
import Payout from '../../../common/components/templates/PayoutModals/Payout/Payout'
import { ViewMonthlyPaymentAmount } from 'common/components/templates/PayoutModals/ViewMonthlyPaymentAmount'

const MonthlyPayout = () => {
  return (
    <Payout>
      <ViewMonthlyPaymentAmount />
    </Payout>
  )
}

export default MonthlyPayout