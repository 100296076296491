import React, { useState, useEffect } from "react";
import ContractApplication from "pages/ContractApplication";
import InfluencerContract from "pages/InfluencerContract";
import UsageDocumentInfo from "pages/UsageDocumentInfo";
import useAuthStore from "store/authStore";
import useRegister from "common/hooks/useRegister";
import useError from "common/hooks/useErrors";
import { getErrorMessage } from "utils/helper";
import { uploadFiletoStorage } from "services/awsFileUpload";
import FormLayout from "common/components/templates/FormLayout";
import RegistrationComplete from "pages/RegistrationComplete";

const components = [
  UsageDocumentInfo,
  InfluencerContract,
  ContractApplication,
  RegistrationComplete,
];
const stepTitles = [
  "サービスご紹介",
  "お申込み内容入力",
  "公式LINE登録",
  "お申込み完了",
];

const Register = () => {
  const [currentPage, setNextpage] = useState(0);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const CurrentComponent = components[currentPage] || null;
  const { onRegister } = useRegister();
  const setError = useError((state) => state.setError);
  const userRegister = useAuthStore((state) => state.userRegister);
  const clearUserData = useAuthStore((state) => state.clearUserRegisterData);
  const selectedFile = useAuthStore((state) => state.selectedFile);
  const setLoading = useAuthStore((state) => state.setLoading);

  useEffect(() => {
    if (isAuthenticated) window.location.href = "/";
  }, [isAuthenticated]);

  const onProceed = async () => {
    if (currentPage < components.length - 2) {
      setNextpage((currentPage) => currentPage + 1);
    } else {
      try {
        if (selectedFile) {
          setLoading(true);
          const filepath: string = await uploadFiletoStorage(selectedFile);
          const prepareData = {
            ...userRegister,
            screenShot: filepath,
          };
          onRegister(prepareData)
            .then((response) => {
              if (response.success) {
                // setError("契約書の申請が完了しました。", "success");
                clearUserData();
                setNextpage((currentPage) => currentPage + 1);
              }
            })
            .catch((error: ResponseError) => {
              setError(getErrorMessage(error?.subStatusCode));
            })
            .finally(() => setLoading(false));
        }
      } catch (e) {
        console.log(e);
        setError(getErrorMessage("default"));
      }
    }
  };

  const onPrevious = () => {
    setNextpage((currentPage) => currentPage - 1);
  };

  return (
    <FormLayout
      stepperVisible
      totalSteps={4}
      currentStep={currentPage + 1}
      stepTitle={stepTitles[currentPage]}
    >
      <CurrentComponent onProceed={onProceed} onPrevious={onPrevious} />
    </FormLayout>
  );
};

export default Register;
