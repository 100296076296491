import React from "react"
import { Routes, Route } from "react-router-dom"
import ForgetPassword from "pages/ForgetPassword"
import Login from "pages/Login"
import PasswordSent from "pages/PasswordSent"
import ResetPassword from "pages/ResetPassword"
import ProtectedRoutes from "./ProtectedRoutes"
import Dashboard from "pages/Dashboard"
import DashboardEn from "pages/en/DashboardEn"
import EditProfile from "pages/EditProfile"
import ContractorDetails from "pages/ContractorDetails"
import ChangePassword from "pages/ChangePassword"
import Register from "pages/Register"
import DashboardNew from "pages/DashboardNew"
import AccountDetail from "pages/AccountDetail"

import RegisterEn from "pages/en/Register"
import ForgetPasswordEn from "pages/en/ForgetPasswordEn/ForgetPasswordEn"
import ENLogin from "pages/en/Login"
import PasswordSentEn from "pages/en/PasswordSentEn"
import ContractorDetailsEn from "pages/en/ContractorDetailsEn"
import EditProfileEn from "pages/en/EditProfile"
import ChangePasswordEn from "pages/en/ChangePassword"
import ResetPasswordEn from "pages/en/ResetPassword"
import AddInfluencer from "pages/AddInfluencer"
import AddInfluencerEn from "pages/en/AddInfluencerEn"
import PayoutPage from "pages/Payout/PayoutPage"
import MonthlyPayout from "pages/Payout/MonthlyPayout"
import PayoutBank from "pages/Payout/PayoutBank"
import EditBankDetails from "pages/Payout/EditBankDetails/EditBankDetails"
import ConfirmBankDetails from "pages/Payout/ConfirmBankDetails"
import ConfirmationPage from "pages/Payout/ConfirmationPage"
import AddBankDetails from "pages/Payout/AddBankDetails/AddBankDetails"
import Invoice from "pages/Invoice"
import CsvUpload from "pages/CsvUpload"

type RouterProps = {
  domain: string
}

export const Router = ({ domain }: RouterProps) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <Dashboard />
          </ProtectedRoutes>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/en/register" element={<RegisterEn />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route
        path="/influencer/password/reset/:id"
        element={<ResetPassword />}
      />
      <Route path="/password-sent" element={<PasswordSent />} />
      <Route
        path={`/en/influencer/password/reset/:id`}
        element={<ResetPasswordEn />}
      />
      <Route path={`/register`} element={<Register />} />
      <Route path="/en/forget-password" element={<ForgetPasswordEn />} />
      <Route path="/en/password-sent" element={<PasswordSentEn />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoutes>
            <Dashboard />
          </ProtectedRoutes>
        }
      />
      {/* <Route
        path="/dashboardnew"
        element={
          <ProtectedRoutes>
            <DashboardNew />
          </ProtectedRoutes>
        }
      /> 
      <Route
        path="/add-influencer-ja"
        element={
          <ProtectedRoutes>
            <AddInfluencer />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/add-influencer-en"
        element={
          <ProtectedRoutes>
            <AddInfluencerEn />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/csv-upload"
        element={
          <ProtectedRoutes>
            <CsvUpload />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/accountdetails"
        element={
          <ProtectedRoutes>
            <AccountDetail />
          </ProtectedRoutes>
        }
      /> */}
      {/* <Route
        path={`/profile/:id`}
        element={
          <ProtectedRoutes>
            <ContractorDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/profile/:id/invoice`}
        element={
          <ProtectedRoutes>
            <Invoice />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/profile/en/:id`}
        element={
          <ProtectedRoutes>
            <ContractorDetailsEn />
          </ProtectedRoutes>
        }
      /> */}
      <Route
        path={`/profile/en/:id/invoice`}
        element={
          <ProtectedRoutes>
            <Invoice />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/edit-profile`}
        element={
          <ProtectedRoutes>
            <EditProfile />
          </ProtectedRoutes>
        }
      />
      {/* <Route
        path={`/payout`}
        element={
          <ProtectedRoutes>
            <PayoutPage />
          </ProtectedRoutes>
        }
      /> */}
      {/* <Route
        path={`/en/payout`}
        element={
          <ProtectedRoutes>
            <PayoutPage />
          </ProtectedRoutes>
        }
      /> */}
      <Route
        path={`/payout/monthly`}
        element={
          <ProtectedRoutes>
            <MonthlyPayout />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/en/payout/monthly`}
        element={
          <ProtectedRoutes>
            <MonthlyPayout />
          </ProtectedRoutes>
        }
      />
      {/* <Route
        path="/payout/bank"
        element={
          <ProtectedRoutes>
            <PayoutBank />
          </ProtectedRoutes>
        }
      /> */}
      {/* <Route
        path="/en/payout/bank"
        element={
          <ProtectedRoutes>
            <PayoutBank />
          </ProtectedRoutes>
        }
      /> */}
      <Route
        path="/payout/bank/add"
        element={
          <ProtectedRoutes>
            <AddBankDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/en/payout/bank/add"
        element={
          <ProtectedRoutes>
            <AddBankDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/payout/bank/edit"
        element={
          <ProtectedRoutes>
            <EditBankDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/en/payout/bank/edit"
        element={
          <ProtectedRoutes>
            <EditBankDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/payout/bank/confirm`}
        element={
          <ProtectedRoutes>
            <ConfirmBankDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/en/payout/bank/confirm`}
        element={
          <ProtectedRoutes>
            <ConfirmBankDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/payout/bank/confirmed`}
        element={
          <ProtectedRoutes>
            <ConfirmationPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/en/payout/bank/confirmed`}
        element={
          <ProtectedRoutes>
            <ConfirmationPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/change-password`}
        element={
          <ProtectedRoutes>
            <ChangePassword />
          </ProtectedRoutes>
        }
      />
      <Route path="/en" element={<ENLogin />} />
      <Route path="/en/login" element={<ENLogin />} />
      <Route
        path="/en/dashboard"
        element={
          <ProtectedRoutes>
            <DashboardEn />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/en/edit-profile"
        element={
          <ProtectedRoutes>
            <EditProfileEn />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/en/change-password"
        element={
          <ProtectedRoutes>
            <ChangePasswordEn />
          </ProtectedRoutes>
        }
      />
      <Route path="*" element={<h1>Page not found</h1>} />
    </Routes>
  )
}
