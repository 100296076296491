import React, { useEffect, useState } from "react"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import styles from "../Payout/Payout.module.scss"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import usePayoutBank from "common/hooks/usePayoutBank"

const AccountRegistrationConfirmation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [bankDetails, setBankDetails] = useState<PayoutBank>()
  const isEnglish = window.location.href.includes("/en/")

  const { payoutBank } = usePayoutBank()

  useEffect(() => {
    if (payoutBank?.success) {
      setBankDetails(payoutBank.result)
    }
  }, [payoutBank])

  return (
    <div className={styles.accountRegistrationConfirmation}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <Text className={styles.title}>
            {t("payout.AccountRegistrationConfirmTitle")}
          </Text>
        </div>
        <div className={styles.transferDetail}>
          <div className={styles.transferDetailHeaderWrapper}>
            <Text className={styles.transferDetailPara}>
              {t("payout.TransferDestinationAccount")}
            </Text>
          </div>
          <div className={styles.transferDetailContent}>
            <Text style={{ width: "250px" }}>
              {bankDetails?.bankName ?? ""}
              <br />
              {bankDetails?.bankBranch ?? ""}
              <br />
              {bankDetails?.bankType ?? ""}
              {bankDetails?.bankType ? <br /> : ""}
              {bankDetails?.bankNo ?? ""}
            </Text>
            {isEnglish && (
              <Text style={{ width: "250px", marginTop: 0 }}>
                {bankDetails?.bankBranchAddress ?? ""}
                <br />
                {bankDetails?.iBan ?? ""}
                <br />
                {bankDetails?.institutionNo ?? ""}
                <br />
                {bankDetails?.swiftCode ?? ""}
                <br />
                {bankDetails?.transit ?? ""}
              </Text>
            )}
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            onClick={() => {
              navigate(isEnglish ? "/en/payout/bank/edit" : "/payout/bank/edit")
            }}
          >
            {t("payout.edit")}
          </Button>
          <Button
            onClick={() => {
              navigate(
                isEnglish
                  ? "/en/payout/bank/confirmed"
                  : "/payout/bank/confirmed"
              )
            }}
          >
            {t("payout.confirmed")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AccountRegistrationConfirmation
