import React, { Fragment, useRef, ChangeEvent, useState } from "react"
import { Link } from "react-router-dom"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import useRegister from "common/hooks/en_hooks/useRegister"
import useError from "common/hooks/useErrors"
import useAuthStore from "store/authStore"
import { uploadFiletoStorage } from "services/awsFileUpload"
import { getErrorMessage } from "utils/helper"
import { emailRegex, passwordRegex } from "utils/constant"
import Loader from "common/components/atoms/Loader"
import OrinasLogo from "common/assets/images/logo-orinas.png"
import styles from "./InfluencerContract.module.scss"

interface InfluencerProps {
  onProceed: () => void
  onPrevious: () => void
}

const InfluencerContract = ({ onProceed, onPrevious }: InfluencerProps) => {
  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Full name is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    address: yup.string().required("Address is required"),
    zipcode: yup.string().required("Zip code is required"),
    apartment: yup.string(),
    city: yup.string().required("City is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required")
      .matches(emailRegex, "Invalid email format"),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")], "Emails do not match")
      .required("Email confirmation is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(passwordRegex, "Invalid password format"),
    mainChannelName: yup.string().required("Main channel name is required"),
    youtubeChannelUrl: yup
      .array()
      .of(
        yup
          .object({ url: yup.string().required("URL is required") })
          .required("At least one YouTube channel URL is required")
      )
      .required("At least one YouTube channel URL is required"),
    youtubeShortCount: yup
      .number()
      .typeError("Please enter a number")
      .required("YouTube short count is required"),
    // .positive("Please enter a number greater than 0")
    agency: yup.boolean().required("Agency information is required"),
    isSponsorShip: yup.boolean().required("required"),
    isCompany: yup.boolean().required("Company information is required"),
    companyName: yup.string().when("isCompany", {
      is: true,
      then: (schema) => schema.required("Company name is required"),
    }),
    companyState: yup.string().when("isCompany", {
      is: true,
      then: (schema) => schema.required("State is required"),
    }),
    companyCountry: yup.string().when("isCompany", {
      is: true,
      then: (schema) => schema.required("Country is required"),
    }),
    averageViewCounts: yup
      .number()
      .typeError("Please enter a number")
      .required("Average view counts is required"),
    // .positive("Please enter a number greater than 0")
    screenShot: yup.string().required("Screenshot is required"),
    inviteName: yup.string(),
  })

  const userRegister = useAuthStore((state) => state.enUserRegister)
  const setUserRegister = useAuthStore((state) => state.setENUserRegister)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const setSelectedFile = useAuthStore((state) => state.setSelectedFile)
  const selectedFile = useAuthStore((state) => state.selectedFile)
  const { onRegister } = useRegister()

  const [isLoading, setLoading] = useState(false)
  const [isCompanyVisible, setCompnayVisible] = useState(false)
  const setError = useError((state) => state.setError)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: userRegister.fullName,
      state: userRegister.state,
      country: userRegister.country,
      address: userRegister.address,
      zipcode: userRegister.zipcode,
      city: userRegister.city,
      email: userRegister.email,
      confirmEmail: userRegister.email,
      password: userRegister.password,
      mainChannelName: userRegister.mainChannelName,
      youtubeChannelUrl: userRegister.youtubeChannelUrl.length
        ? userRegister.youtubeChannelUrl.map((url) => ({ url }))
        : [{ url: undefined }],
      youtubeShortCount: userRegister.youtubeShortCount,
      agency: userRegister.agency,
      isCompany: userRegister.isCompany,
      companyName: userRegister.isCompany ? userRegister.companyName : "",
      averageViewCounts: userRegister.averageViewCounts,
      screenShot: selectedFile?.name || "",
      isSponsorShip: userRegister.isSponsorShip,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "youtubeChannelUrl",
  })

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      setValue("screenShot", selectedFile.name)
      setSelectedFile(selectedFile)
    }
  }

  const handleRegister = async (values: any) => {
    try {
      if (selectedFile) {
        setLoading(true)
        const filepath: string = await uploadFiletoStorage(selectedFile)
        const prepareData = {
          ...values,
          youtubeChannelUrl: values.youtubeChannelUrl.map(
            (item: { url: any }) => item.url
          ),
          companyName: values.isCompany ? values.companyName : "",
          inviteName: values.inviteName?.length ? values.inviteName : null,
          screenShot: filepath,
        }
        onRegister(prepareData)
          .then((response) => {
            if (response.success) {
              // clearUserData()
              onProceed()
            }
          })
          .catch((error: ResponseError) => {
            setError(getErrorMessage(error?.subStatusCode))
          })
          .finally(() => setLoading(false))
      }
    } catch (e) {
      console.log(e)
      setError(getErrorMessage("default"))
    }
    // setUserRegister(prepareData)
    // onProceed()
  }

  return (
    <>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={OrinasLogo} alt="" />
      </div>
      <Title order={5} color="purple" fw="bold">
        Registration Form
      </Title>
      <div className={styles.agencyCheckBox}>
        <Text sz="xxs" fw="bold">
          I would like a corporate contract.
        </Text>
        <input
          type="checkbox"
          {...register("isCompany")}
          onClick={() => setCompnayVisible(!isCompanyVisible)}
        />
        {/* <Text sz="xxs" fw="bold" style={{ width: "auto" }}>
          YES
        </Text> */}
      </div>
      {isCompanyVisible && (
        <>
          <Input
            label={"Corporate Name"}
            id={"companyName"}
            inputProps={{ ...register("companyName") }}
            error={errors.companyName?.message}
          />
          <Input
            label={"State/province (established by law)"}
            id={"companyState"}
            inputProps={{ ...register("companyState") }}
            error={errors.companyState?.message}
          />
          <Input
            label={"Country (established by law)"}
            id={"companyCountry"}
            inputProps={{ ...register("companyCountry") }}
            error={errors.companyCountry?.message}
          />
        </>
      )}
      <Input
        label={"Full Name*"}
        id={"fullName"}
        inputProps={{ ...register("fullName") }}
        error={errors.fullName?.message}
      />
      <Input
        label={"Address*"}
        id={"address"}
        inputProps={{ ...register("address") }}
        error={errors.address?.message}
      />
      <Input
        label={"Apartment, suite, etc."}
        id={"apartment"}
        inputProps={{ ...register("apartment") }}
        error={errors.apartment?.message}
      />
      <Input
        label={"City*"}
        id={"city"}
        inputProps={{ ...register("city") }}
        error={errors.city?.message}
      />
      <Input
        label={"State/province*"}
        id={"state"}
        inputProps={{ ...register("state") }}
        error={errors.state?.message}
      />
      <Input
        label={"Country*"}
        id={"country"}
        inputProps={{ ...register("country") }}
        error={errors.country?.message}
      />
      <Input
        label={"ZIP/postal code*"}
        id={"zipcode"}
        inputProps={{ ...register("zipcode") }}
        error={errors.zipcode?.message}
      />
      <Input
        label={"Email*"}
        id={"email"}
        inputProps={{ ...register("email") }}
        error={errors.email?.message}
      />
      <Input
        label={"Confirm email*"}
        id={"confirmEmail"}
        inputProps={{ ...register("confirmEmail") }}
        error={errors.confirmEmail?.message}
      />
      <div style={{ width: "100%" }}>
        <Text sz="xxs" fw="bold">
          Password*
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          This password will be required for invoice verification.
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・6 or more characters
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・Upper & lowercase letters
        </Text>
        <Text sz="xxs" fw="bold" color="grey">
          ・At least one number
        </Text>
        <Input
          label={""}
          id={"password"}
          inputProps={{ ...register("password") }}
          error={errors.password?.message}
          type="password"
        />
      </div>
      <Input
        label={"YouTube channel Name*"}
        id={"mainChannelName"}
        inputProps={{ ...register("mainChannelName") }}
        error={errors.mainChannelName?.message}
      />
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Input
            label={`YouTube Channel URL*`}
            id={`youtubeChannelUrl.${index}.url`}
            inputProps={{ ...register(`youtubeChannelUrl.${index}.url`) }}
            error={errors.youtubeChannelUrl?.[index]?.url?.message}
          />
          {index !== 0 && (
            <Button variant="purple" onClick={() => remove(index)}>
              - remove URL
            </Button>
          )}
        </Fragment>
      ))}
      <Button
        style={{
          border: "2px solid #ffa500",
          background: "transparent",
          color: "#ffa500",
        }}
        variant="purple"
        onClick={() => append({ url: "" })}
      >
        Add more URLs
      </Button>
      <Input
        label={"How often do you post YouTube shorts?*"}
        id={"youtubeShortCount"}
        inputProps={{ ...register("youtubeShortCount") }}
        error={errors.youtubeShortCount?.message}
      />
      <Input
        label={"How many average monthly views do you have?*"}
        id={"averageViewCounts"}
        inputProps={{ ...register("averageViewCounts") }}
        error={errors.averageViewCounts?.message}
      />
      <Input
        label={
          "Has someone referred you to us? If yes, please provide us with their YouTube channel name."
        }
        id={"inviteName"}
        inputProps={{ ...register("inviteName") }}
        error={errors.inviteName?.message}
      />
      {!isCompanyVisible && (
        <div className={styles.agencyCheckBox}>
          <Text sz="xxs" fw="bold">
            I am affiliated with a talent agency.
          </Text>
          <input type="checkbox" {...register("agency")} />
          {/* <Text sz="xxs" fw="bold" style={{ width: "auto" }}>
            YES
          </Text> */}
        </div>
      )}
      <div className={styles.agencyCheckBox}>
        <Text sz="xxs" fw="bold">
          I would like to receive invitations for sponsorship projects.
        </Text>
        <input type="checkbox" {...register("isSponsorShip")} />
        {/* <Text sz="xxs" fw="bold" style={{ width: "auto" }}>
          YES
        </Text> */}
      </div>
      <div style={{ width: "100%" }}>
        <Text sz="xxs" fw="bold">
          Please upload a screenshot showing your YouTube Shorts' playback count
          over the past 3 months.*
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          1.Open "YT Studio."
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          2.Go to the bottom and click on "Analytics."
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          3.At the top, select "Content."
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          4.Choose "YouTube Shorts."
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          5.View count from Shorts.
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          6.Set the top to "last 90 days."
        </Text>
        <Text sz="xxs" color="grey" fw="bold">
          7.Take a screenshot.
        </Text>
      </div>
      <Button
        style={{
          border: "2px solid #ffa500",
          background: "transparent",
          color: "#ffa500",
        }}
        variant="yellow"
        onClick={handleButtonClick}
      >
        Upload Screenshot
      </Button>
      {errors.screenShot?.message && !selectedFile && (
        <Text sz="xxs" color="red" style={{ marginTop: "-20px" }}>
          {errors.screenShot?.message}
        </Text>
      )}
      {selectedFile && (
        <>
          <Text sz="xxs" style={{ marginTop: "-20px" }} color="green">
            uploaded the image.
          </Text>
          <img
            src={URL.createObjectURL(selectedFile)}
            className={styles.previewImage}
            alt="screenshot"
          />
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept=".png, .jpg, .jpeg"
      />
      <Button
        // variant={"yellow"}
        variant={"purple"}
        style={{ marginTop: "0" }}
        disabled={isLoading}
        onClick={handleSubmit(handleRegister)}
      >
        {isLoading && <Loader />}
        Submit
      </Button>
      {/* <Button variant={"purple"} onClick={onPrevious}>
        Back
      </Button> */}
      <p className={styles.linkTo}>
        Orinas will use your personal data as described in our &#160;
        <Link to="https://orinas.jp/en-privacy-policy/" className={styles.link}>
          Privacy Policy.
        </Link>
      </p>
    </>
  )
}

export default InfluencerContract
