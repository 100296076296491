import EN_BUTTONS from './buttons.json'
// import EN_CHANGE_STATUS from './changeStatus.json'
import EN_COMMON from './common.json'
import EN_ERRORS from './errors.json'
import EN_FIELDS from './fields.json'
import EN_LABELS from './labels.json'
import EN_LINKS from './links.json'
import EN_NAVIGATIONS from './navOptions.json'
// import EN_ORDER_STATUS from './orderStatus.json'
// import EN_PAYMENT_TYPES from './paymentTypes.json'
import EN_PLACEHOLDERS from './placeholders.json'
import EN_TABLE_TITLES from './tableTitles.json'
import EN_TITLES from './titles.json'
import EN_PAYOUT from './payout.json'

export const translations = {
  buttons: EN_BUTTONS,
  // changeStatus: EN_CHANGE_STATUS,
  common: EN_COMMON,
  errors: EN_ERRORS,
  fields: EN_FIELDS,
  labels: EN_LABELS,
  links: EN_LINKS,
  navOptions: EN_NAVIGATIONS,
  // orderStatus: EN_ORDER_STATUS,
  // paymentTypes: EN_PAYMENT_TYPES,
  placeholders: EN_PLACEHOLDERS,
  tableTitles: EN_TABLE_TITLES,
  titles: EN_TITLES,
  payout: EN_PAYOUT,
}
