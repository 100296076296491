export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const passwordRegex =
  /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{6,}$/

export const phoneNumberRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/

export const ja_errors = {
  emailRequired: "メールアドレスは必要です",
  invalidEmail: "無効なメールアドレスです",
  emailNotExist: "メールアドレスが存在しません",
  confirmEmailNotMatch: "メールアドレスと確認用メールアドレスが一致しません",
  emailAlreadyInUse: "既にメールが登録済みです。",
  passwordRequired: "パスワードが必要です",
  invalidPassword:
    "パスワードは6文字以上で、少なくとも大文字、小文字、数字を1つずつ含む必要があります",
  confirmPasswordNotMatch: "パスワードと確認用パスワードが一致しません",
  passwordNotMatch: "パスワードが一致しません",
  nameRequired: "名前は必須です",
  address: "住所は必須です",
  phone: "電話番号は必須です",
  invalidPhone: "無効な電話番号",
  postalCode: "無効な郵便番号です",
  unknownError: "何か問題が発生しました",
  notcontracted: "契約後にログインができるようになります。",
  userDeleted: "既に契約が破棄されたアカウントです。",
  userDeclined: "ユーザーアプリケーションは辞退されました。",
  noDocumentFound: "ドキュメントが存在しません",
}

export const contractStatus: Status = {
  Applying: "申請中(オリナス対応)",
  Inprogress: "契約申請中(契約者対応)",
  Approval: "最終確認(オリナス対応)",
  Declined: "契約拒否",
  Contracted: "契約済",
}

export const en_errors = {
  emailRequired: "Email is required",
  invalidEmail: "Invalid email address",
  emailNotExist: "Email does not exist",
  confirmEmailNotMatch: "Email and confirm email do not match",
  emailAlreadyInUse: "Email is already in use",
  passwordRequired: "Password is required",
  invalidPassword:
    "Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, and one number",
  confirmPasswordNotMatch: "Password and confirm password do not match",
  passwordNotMatch: "Passwords does not match",
  nameRequired: "Name is required",
  address: "Address is required",
  phone: "Phone number is required",
  invalidPhone: "Invalid phone number",
  postalCode: "Invalid postal code",
  unknownError: "An unknown error occurred",
  notcontracted: "You will be able to log in after contracting.",
  userDeleted: "Account has already been deleted.",
  userDeclined: "User application has been declined.",
  noDocumentFound: "Document not found",
}
